import {h, render, Component } from 'preact';
import { getQueryParam, getReferrerLinksFromLS, saveReferrerLinkToLS, popupCenter, locales } from './utils';
import './styles.scss';
/** @jsx h */

/* global twoKey */
/* global apiUrl */
/* global ga */
/* global gaKey */

/* eslint-disable */
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
/* eslint-enable */

const container = document.getElementById('twoKeyShareAndEarn');
const lang = container.lang || 'en';

class ShareButton extends Component {
  static defaultProps = {
    bc: window.TWOKEY_SHARE_CONFIG.bc || '#17936F',
    tk: window.TWOKEY_SHARE_CONFIG.tk || '#0FED8B',
    tc: window.TWOKEY_SHARE_CONFIG.tc || '#ffffff',
    ca: window.TWOKEY_SHARE_CONFIG.ca || 'Refer & Earn',
    lg: window.TWOKEY_SHARE_CONFIG.lg || lang,
    bh: window.TWOKEY_SHARE_CONFIG.bh || container.dataset.page
  }
  state = {
    visible: false,
    params: {
      '2kr': getQueryParam('2kr'),
      '2ks': getQueryParam('2ks'),
      '2kc': getQueryParam('2kc'),
    }
  }

  addCss() {
    const {
      bc, tk, tc
    } = this.props
    const css = `
    #twoKeyShareAndEarn .twokey-share-btn {
      background-color: ${bc} !important;
    }
    #twoKeyShareAndEarn .twokey-share-btn p{
      padding-bottom: 0 !important;
    }
    #twoKeyShareAndEarn .twokey-token-logo div{
        background-color: ${tk} !important;
        border: 2px solid ${bc} !important;
    }
    #twoKeyShareAndEarn .twokey-share-btn .twokey-share-btn_refer {
      color: ${tc} !important;
      margin: 0 0 3px 0 !important;
      font-size: 18px !important;
      padding-bottom: 0 !important;
      padding-top: 0 !important
    }
    #twoKeyShareAndEarn .twokey-share-btn-owner {
      color: ${tk} !important;
      font-size: 8px !important;
      letter-spacing: 2px !important;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
      opacity: 1
    }
    .twokey-primary .twokey-share-btn-owner {
      margin-bottom: 0;
      text-align: left;
    }
    `
    const head = document.head;
    const style = document.createElement('style')
    head.appendChild(style)
    style.type = 'text/css'
    if(style) {
      style.appendChild(document.createTextNode(css))
    }
  }

  componentDidMount() {
    const state = {...this.state}
    this.addCss()
    ga('create', gaKey, 'auto');
    let r = state.params['2kr']; // ipfs referrer hash
    let s = state.params['2ks']; // f_secret
    let c = state.params['2kc']; // cf_campaign_hash
    if (!r || !s || !c) {
      const referralLink = getReferrerLinksFromLS();
      if (referralLink) {
        r = referralLink['2kr'];
        s = referralLink['2ks'];
        c = referralLink['2kc'];
        this.setState({
          ...state,
          params: {
            ...referralLink
          }
        })
      }
    } else if (!getReferrerLinksFromLS()) {
      saveReferrerLinkToLS();
    }
    let url = `${apiUrl}/campaign?business_hash=${this.props.bh}&target_url=${encodeURIComponent(window.location.href)}`;
    if (c) { // if we have campaign_hash in params or LS use it in BE call
      url += `&cf_campaign_hash=${c}`;
    }
    if (r) { // if we land from referrer link we send it as seed_hash_from_url param to BE
      url += `&seed_hash_from_url=${encodeURIComponent(r)}`;
    }
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: 'Bearer GUEST',
      },
    }).then(res => res.json()).then(data => {
      if (data && data.campaign && data.campaign.sourcing_seed_hash_full) {
        const seedHashArray = data.campaign.sourcing_seed_hash_full.split('?');
        if (!r || !s || !c) {
          const contractorLink = { '2kr': seedHashArray[0], '2ks': seedHashArray[1].replace('s=', ''), '2kd': true };
          this.setState(() => ({
            ...state,
            params: {...contractorLink},
          }))
          saveReferrerLinkToLS(contractorLink);
        }
        const label = data.campaign.business_handle;
        const value = data.campaign.id;
        const referrerdByContractor = (data.referral_links[0] || {}).referrer_is_contractor;
        const action = c
          ? ((referrerdByContractor && 'Visit Target From Contractor Preview') || 'Visit Target From Referrer Preview')
          : 'Visit Target Directly';
        // console.log('EMIT_GA_EVENT', {
        //   hitType: 'event',
        //   eventCategory: 'Visit SL Target',
        //   eventAction: action,
        //   eventLabel: label,
        //   eventValue: value,
        //   nonInteraction: true,
        //   dimension1: String(value),
        //   '2key_campaign_id': String(value),
        // });
        // ga('send', 'event', 'Visit SL Target', action, label, value);
        ga('send', {
          hitType: 'event',
          eventCategory: 'Visit SL Target',
          eventAction: action,
          eventLabel: label,
          eventValue: value,
          nonInteraction: true,
          dimension1: String(value),
          '2key_campaign_id': String(value),
        });
        this.setState((prevState) => ({
          ...prevState,
          visible: true
        }))
      } else {
        console.warn('Campaign not exists!');
      }
    });
  }


  onClick = () => {
    const state = {...this.state}
    const url = `${twoKey}/${state.params['2kr']}/cpc-preview?s=${state.params['2ks']}&share_only=true&2kp=${this.props.bh}${
      state.params['2kd'] ? '&2kd=true' : ''}&ref=${encodeURIComponent(window.location.href)}`;
    const isMobile = window.innerWidth <= 575;
    popupCenter({
      url,
      title: '2key',
      ww: isMobile ? window.innerWidth : 680,
      wh: isMobile ? window.innerHeight : 500,
      skipSystemZoom: true,
      isMobile,
    });
  }

  render() {
    const {ca,lg} = this.props
    const {visible} = this.state
    return visible && (
      <button className="twokey-share-btn" lang={lg} onClick={this.onClick}>
          <div className="twokey-share-btn_refer" lang={lg}>
            <div className="twokey-token-logo"><div /><div /></div>
            <div>
              <p className="twokey-share-btn_refer" lang={lg}>
                {ca}
              </p>
              <p className="twokey-share-btn-owner">powered by 2key</p>
            </div>
          </div>
      </button>
    );
  }
}

render(<ShareButton />, container);
