import sha from 'sha1';

export const popupCenter = ({
  url, title, ww, wh, skipSystemZoom,
}) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : (document.documentElement.clientWidth && document.documentElement.clientWidth) || screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : (document.documentElement.clientHeight && document.documentElement.clientHeight) || screen.height;

  const systemZoom = skipSystemZoom ? 1 : width / window.screen.availWidth;
  const left = ((width - ww) / 2 / systemZoom) + dualScreenLeft;
  const top = ((height - wh) / 2 / systemZoom) + dualScreenTop;
  const newWindow = window.open(
    url, title,
    `
      width=${ww / systemZoom}, 
      height=${wh / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  );

  if (window.focus) newWindow.focus();
};

export function getQueryParam(param) {
  const rx = new RegExp(`[?&]${param}=([^&]+).*$`);
  const returnVal = window.location.search.match(rx);
  return returnVal === null ? '' : returnVal[1];
}

/* eslint-disable no-restricted-globals */
export function saveReferrerLinkToLS(referralLink) {
  const key = sha(window.location.pathname);
  try {
    localStorage.setItem(key, JSON.stringify(referralLink
      || {
        '2kr': getQueryParam('2kr'),
        '2ks': getQueryParam('2ks'),
        '2kc': getQueryParam('2kc'),
      }));
  } catch (err) {
    console.warn(err);
  }
}

export function getReferrerLinksFromLS() {
  try {
    const key = sha(window.location.pathname);
    const storedItem = localStorage.getItem(key);
    if (storedItem) {
      if (storedItem['2kr'] && storedItem['2ks'] && storedItem['2kc']) {
        return JSON.parse(storedItem);
      }
      localStorage.removeItem(key);
      return null;
    }
    return null;
  } catch (err) {
    console.warn(err);
    return null;
  }
}
/* eslint-enable no-restricted-globals */

export const locales = {
  de: { refer: 'Verweisen', and: '&', earn: 'Verdienen' },
  en: { refer: 'Refer', and: '&', earn: 'Earn' },
  es: { refer: 'Recomiende', and: 'y', earn: 'Gane' },
  fr: { refer: 'Partagez', and: 'et', earn: 'Gagnez' },
  id: { refer: 'Lihat', and: '&', earn: 'Dapatkan' },
  ja: { refer: '紹介', and: 'し', earn: 'て稼ぐ' },
  ko: { refer: '추천', and: '및', earn: '적립' },
  ru: { refer: 'Делитесь', and: 'и', earn: 'Зарабатывайте' },
  vi: { refer: 'Giới thiệu', and: '&', earn: 'Kiếm tiền' },
  zh: { refer: '推荐', and: '并', earn: '赚钱' },
};
